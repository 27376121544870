import React from 'react';

const Projects = () => {
    return (
      <section id="projects" className="p-12 bg-gradient-to-b from-white to-gray-100 min-h-screen">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-16">
          Projects
        </h2>
  
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">

            {/* TargetX Project */}
          <div className="relative bg-white shadow-lg rounded-xl p-8 transition-transform transform hover:scale-105 hover:shadow-2xl duration-300">
            <h3 className="text-xl font-semibold text-blue-600 mb-3 border-b-2 pb-2 border-blue-500 hover:text-blue-800 transition-colors">
              <a href="https://gyanmatrix.com/" target="_blank" rel="noopener noreferrer">
                Gyanmatrix Technologies
                <h5 className='text-xs text-black'>{'(Nov 2022 - Present)'}</h5>
              </a>
            </h3>
            <p className="text-gray-600 mb-4">
            <a href="https://www.targetx.com/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue:800">
                TargetX
              </a> is trusted Higher Ed CRM solutions for full student lifecycle management. We have multiple products which I work on such as TxAppointmentScheduler, TxForms, TxEngage, Tx-OnlineApplication, LiaisonComponentLibrary(LCL) each having it's own techstack which handles entire student life cycle management from admission till placements and alumni.
            </p>
            <div className="text-sm text-gray-500">
              <p className="font-bold mb-2">Role:</p>
              <ul className="list-disc list-inside ml-4">
                <li>Designing variety of products, developed using different techstacks</li>
                <li>Developed custom components in react js which are used across multiple teams and multiple products.</li>
              </ul>
              <p className="font-bold mt-4">Environment:</p>
              <p>NextJS, ReactJS, Angular 8+, AngularJS, NodeJS, Salesforce</p>
            </div>
          </div>
          
          {/* EventBay Project */}
          <div className="relative bg-white shadow-lg rounded-xl p-8 transition-transform transform hover:scale-105 hover:shadow-2xl duration-300">
            <h3 className="text-xl font-semibold text-blue-600 mb-3 border-b-2 pb-2 border-blue-500 hover:text-blue-800 transition-colors">
              <a href="https://www.publicissapient.com" target="_blank" rel="noopener noreferrer">
                Publicis Sapient
                <h5 className='text-xs text-black'>{'(Mar 2022 - Nov 2022)'}</h5>
              </a>
            </h3>
            <p className="text-gray-600 mb-4">
            EventBay, The XT event manager, is a web application that tries to centralize XT event management. Workshops, tech connects, seminars, and other events may all be planned and managed through the platform. User can be the person who wants to create the event, or can be the person who wants to apply as a presenter for the event. Admin can approve or reject events as well as approve or reject presenters.
            </p>
            <div className="text-sm text-gray-500">
              <p className="font-bold mb-2">Role:</p>
              <ul className="list-disc list-inside ml-4">
                <li>Designed, developed, and deployed the app</li>
                <li>Server-side scripting for email, created REST Apis , created components , created docker images</li>
                <li>State management using Redux</li>
              </ul>
              <p className="font-bold mt-4">Environment:</p>
              <p>ReactJS, MongoDB, NodeJS, ExpressJS, GraphQL, Docker, Redux</p>
            </div>
          </div>

          {/* EKAL Project */}
          <div className="relative bg-white shadow-lg rounded-xl p-8 transition-transform transform hover:scale-105 hover:shadow-2xl duration-300">
            <h3 className="text-xl font-semibold text-blue-600 mb-3 border-b-2 pb-2 border-blue-500 hover:text-blue-800 transition-colors">
              <a href="https://www.winspiresolutions.com/" target="_blank" rel="noopener noreferrer">
                Winspire Technologies
                <h5 className='text-xs text-black'>{'(July 2020 - Feb 2022)'}</h5>
              </a>
            </h3>
            <p className="text-gray-600 mb-4">
            This mobile and web application provides a service platform with personal care, household care, housekeeping, cooking and many other services and is currently targeted for Saudi Arabia. This application deals with the services which are ordered from consumers, manages sales orders, manages inventory (of workers), QR is generated based on theinformation, and the user can scan the code for payment where the user is redirected to a PayPal page for further processing.  *The scope of this application is somewhat like the scope of UrbanClap/UrbanCompany in India.
            </p>
            <div className="text-sm text-gray-500">
              <p className="font-bold mb-2">Role:</p>
              <ul className="list-disc list-inside ml-4">
                <li>Designed, developed, and deployed the app</li>
              </ul>
              <p className="font-bold mt-4">Environment:</p>
              <p>Ionic 4, Angular 8+, Microsoft Dynamics Business Central</p>
            </div>
          </div>
  
          {/* GeoMap Project */}
          <div className="relative bg-white shadow-lg rounded-xl p-8 transition-transform transform hover:scale-105 hover:shadow-2xl duration-300">
            <h3 className="text-xl font-semibold text-blue-600 mb-3 border-b-2 pb-2 border-blue-500 hover:text-blue-800 transition-colors">
              <a href="https://www.tcs.com" target="_blank" rel="noopener noreferrer">
                TCS
                <h5 className='text-xs text-black'>{'(Jan 2017 - Jun 2020)'}</h5>
              </a>
            </h3>
            <p className="text-gray-600 mb-4">
            <a href="https://www.holcim.com/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue:800">
                Lafarge Holcim
              </a> - GeoMap under the client LafargeHolcim is an offline first mobile application which was created as a part of “TCS Mobility team” at Client (LafargeHolcim) level. This app provides a platform for users from the African continent where they have a minimum internet connection. This application also allows users to click actual waste photos, store device location using gps chip or network, store all information locally and send requests to approvers when the device is back online.
            </p>
            <div className="text-sm text-gray-500">
              <p className="font-bold mb-2">Role:</p>
              <ul className="list-disc list-inside ml-4">
                <li>Designed, developed, and deployed the app to Play Store and App Store</li>
                <li>Server-side scripting for push notifications</li>
              </ul>
              <p className="font-bold mt-4">Environment:</p>
              <p>Ionic 3, Angular 6+, NodeJS, Google Firebase DB</p>
            </div>
          </div>

        </div>
      </section>
    );
  };
  
  
  
  export default Projects;
  