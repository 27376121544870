import React, { useState } from 'react';
import { FaDownload, FaEnvelope, FaGithub, FaLinkedin } from 'react-icons/fa';
import { FiMenu, FiX } from 'react-icons/fi'; // Hamburger and Close icons

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-gray-800 text-white p-4 sticky top-0 z-50">
      <nav className="flex justify-between items-center">
        <div className="text-xl font-bold">Sourabh Mahajan</div>

        {/* Hamburger Icon (shown on mobile screens) */}
        <div className="md:hidden">
          <button onClick={toggleSidebar}>
            {isOpen ? <FiX size={30} /> : <FiMenu size={30} />}
          </button>
        </div>

        {/* Links (visible on larger screens) */}
        <ul className="hidden md:flex space-x-4 flex items-center">
        <div className="flex justify-center space-x-4">
        {/* LinkedIn */}
        <a href="https://www.linkedin.com/in/sourabh-mahajan-489392117/" target="_blank" rel="noopener noreferrer" className="flex items-center bg-blue-600 text-white rounded-lg px-2 py-1 transition-transform transform hover:scale-105 hover:bg-blue-700 duration-300">
          <FaLinkedin size={24} />
        </a>

        {/* GitHub */}
        <a href="https://github.com/sourabh0110" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 bg-gray-800 text-white rounded-lg px-2 py-1 transition-transform transform hover:scale-105 hover:bg-gray-900 duration-300">
          <FaGithub size={24} />
        </a>

      </div>
          <li><a href="#about">About</a></li>
          <li><a href="#projects">Projects</a></li>
          <li><a href="#responsibilites">Roles</a></li>
          <li><a href="#contact">Contact</a></li>
          <li>
        <a 
          href="/assets/cv.docx" 
          download 
          className="flex items-center bg-green-700 text-white rounded-lg px-4 py-2 transition-transform transform hover:scale-105 hover:bg-green-800 duration-300"
        >
          <FaDownload className="mr-2" />
          Download CV
        </a>
      </li>
        </ul>

        {/* Sidebar (visible on mobile when toggled) */}
        <div
          className={`fixed top-0 left-0 h-full w-64 bg-gray-900 text-white p-4 transform ${
            isOpen ? 'translate-x-0' : '-translate-x-full'
          } transition-transform duration-300 ease-in-out md:hidden`}
        >
          <ul className="flex flex-col space-y-4">
            <li><a href="#about" onClick={toggleSidebar}>About</a></li>
            <li><a href="#projects" onClick={toggleSidebar}>Projects</a></li>
            <li><a href="#responsibilites" onClick={toggleSidebar}>Roles</a></li>
            <li><a href="#contact" onClick={toggleSidebar}>Contact</a></li>
            <li><a href="/src/assets/cv.docx" 
          download  onClick={toggleSidebar}>Download CV</a></li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
